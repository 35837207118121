/* Delivery */
.no-select {
	user-select: none;
}

.delivery-top-menu {
	display: flex;
	width: 100%;
	padding: 0 10px;
}

.delivery-top-menu-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 18px;
	width: 120px;
	height: 75px;
	padding: 0 !important;
	position: relative;
}

.delivery-calendar {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	cursor: pointer;
	font-size: 18px;
	width: 100%;
}

.delivery-top-menu-item i,
.delivery-calendar i {
	font-size: 30px;
}

.delivery-top-menu-item.active {
	color: #5b73e8 !important;
}

.delivery-statuses {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.delivery-status {
	padding: 15px 0;
	cursor: pointer;
	width: calc(100% / 5);
	text-align: center;
}

.delivery-status {
	background-color: #6c757d;
	color: #fff;
}

.delivery-status.active {
	background-color: #5b73e8;
}

.delivery-status span {
	font-weight: 700;
}

.delivery-footer {
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 1000;
	width: 100%;
	height: 50px;
	box-shadow: 0 0 5px rgb(15 34 58 / 25%);
	display: flex;
	padding: 0px 10px;
	background-color: #fff;
	justify-content: space-between;
}

.delivery-footer-item {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 16px;
	width: 120px;
	background: transparent;
	border: none;
	color: #000;
	padding: 0;
}

.delivery-footer-item:hover {
	color: #5b73e8;
}

.delivery-footer-search {
	position: relative;
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.delivery-footer-search input {
	width: 100%;
	height: 70%;
	border: 1px solid #9b9b9b;
	border-radius: 6px;
	outline: none;
	padding: 6px 12px;
	padding-left: 32px;
}

.delivery-footer-search input::placeholder {
	color: #9b9b9b;
}

.delivery-footer-search i {
	position: absolute;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	color: #9b9b9b;
}

.delivery-footer-item i {
	font-size: 30px;
}

.delivery-footer-btn {
	height: 37px;
	width: 250px;
	color: #fff;
	background-color: #5B73E8;
	border: none;
	border-radius: 6px;
}

.delivery-table th {
	font-size: 16px;
}

.delivery-table td {
	line-height: 14px;
	vertical-align: middle;
	background-color: #fff;
}

.delivery-table .bottom-tr td {
	height: 30px;
}

.delivery-top-menu-item .react-datepicker-wrapper {
	position: absolute;
	left: 0;
	opacity: 0;
	cursor: pointer;
}

.delivery-date-picker {
	/* position: absolute; */
	width: 100%;
	cursor: pointer;
}

.delivery-address {
	width: 150px;
	overflow: hidden;
}

.client_address {
	height: 53px;
	position: relative;
	margin-bottom: 6px;
	font-weight: 500;
	cursor: pointer;
	padding: 7px 10px;
	background: #F5F6F8;
	border: 1px solid #ced4da;
	border-radius: 6px;
	padding-right: 25px;
	line-height: 13px;
}

.client_address_km {
	position: absolute;
	right: 5px;
	top: 2px;
	font-size: 12px;
}

.client_address_btn {
	position: absolute;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
}

.client_address.active {
	color: #5b73e8;
}

/* Statuses */

/* .table-bg-yellow td:nth-child(-n+3),
.status-1 td:nth-child(-n+3),
.status-8 td:nth-child(-n+3) {
	background-color: #f1b44c;
	color: #fff;
} */

.table-bg-green td:nth-child(-n+3),
.status-9 td:nth-child(-n+3),
.status-10 td:nth-child(-n+3) {
	background-color: #04a10a;
	color: #fff;
}

.delivery-table td.bg-1 {
	background-color: #D5D5D5;
}

.delivery-table td.bg-2 {
	background-color: #EADFD6;
}

.delivery-table td.bg-3 {
	background-color: #04a10a;
	color: #fff;
}

.delivery-table td.bg-4 {
	background-color: #C1C7C9;
	color: #fff;
}

/* .status-0 td:nth-child(-n+3),
.status--15 td:nth-child(-n+3),
.status--20 td:nth-child(-n+3) */

.table-bg-red td:nth-child(-n+3) {
	background-color: #f46a6a;
	color: #fff;
}

/* Statuses end */

/* Delivery */

/* WAITER */
.table-modal-hall {
	background: #DFE3E7;
	border-radius: 10px;
	padding: 20px;
	font-weight: 600;
	font-size: 16px;
}

.header-date {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #9D9B9B;
}

.header-account-name {
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	color: #1E1D1D;
}

.header-right {
	display: flex;
	align-items: center;
	gap: 30px;
}

.header-icon {
	width: 40px;
	height: 40px;
	color: #1E1D1D;
}

.header-action-wrapper {
	display: flex;
	gap: 10px;
	width: 33%;
}

.header-action-button {
	width: 105px;
	height: 100%;
	background-color: #CE34AC;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	border-radius: 10px;
	line-height: 19px;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
}

.waiter-header {
	display: flex;
	justify-content: space-between;
	height: 70px;
	padding: 7px;
}

.waiter-body {
	display: flex;
	height: calc(100vh - 120px);
}

.waiter-body-left {
	overflow-y: auto;
	width: 265px;
	padding: 5px;
	border-radius: 0px 10px 0px 0px;
	background-color: #fff;
}

.waiter-body-right {
	width: 80%;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	padding: 0 15px;
}

.waiter-body .item-box {
	display: flex;
	background: #DFE3E7;
	border-radius: 10px;
	height: 60px;
	margin-bottom: 5px;
}

.waiter-body .item-box-inner {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background: #EAF1F4;
	border-radius: 10px;
	width: 50px;
	height: 60px;
	font-weight: 600;
	margin-left: -2px;
}

.waiter-body .item-box-inner-name {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	margin-left: 10px;
}

.active-tab {
	background: #26AEFB !important;
	color: #FFFFFF !important;
}

.waiter-body .item-box-inner span:nth-child(1) {
	color: #1E1D1D;
}

.waiter-body .item-box-inner span:nth-child(2) {
	color: #1E1D1D;
}

.waiter-body .item-box-inner span:nth-child(3) {
	color: #1E1D1D;
}

.table-box {
	padding: 5px;
	text-align: center;
	min-width: max-content;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 62px;
	height: 75px;
	background: #DFE3E7;
	border-radius: 10px;
	margin-right: 5px;
	margin-bottom: 5px;
	font-weight: 700;
	font-size: 20px;
}

.table-box-active {
	background-color: #26AEFB;
	color: #fff;
}

.table-box-reserve {
	background-color: #E91548;
	color: #fff;
}

.by-waiters-table-box {
	width: 236px;
	height: 180px;
	background: #fff;
	border-radius: 5px;
	margin-right: 5px;
	margin-bottom: 5px;
	border: 1px solid #A0A0A0;
}

.by-waiters-table-box-empty {
	width: 236px;
	height: 180px;
	background: #DFE3E7;
	border-radius: 5px;
	margin-right: 5px;
	margin-bottom: 5px;
}

.by-waiters-table-box-bottom {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 35px;
}

.by-waiters-cheque-products {
	overflow-y: auto;
	width: 100%;
	height: 145px;
	padding: 8px 10px 0 0;
}

.by-waiters-cheque-item {
	display: flex;
	margin-bottom: 2px;
}

.by-waiters-cheque-product-quantity {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	min-width: 30px;
	width: 30px;
	height: 20px;
	color: #1E1D1D;
	background: #DFE3E7;
	border: 1px solid #DFE3E7;
	border-radius: 0px 10px 10px 0px;
	font-weight: 600;
	font-size: 14px;
	margin-right: 5px;
}

.by-waiters-cheque-product-name {
	color: #1E1D1D;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	height: 20px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.by-waiters-table-number {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 89px;
	height: 34px;
	background: #EAF1F4;
	border: 1px solid #A0A0A0;
	border-radius: 0px 5px;
	font-weight: 700;
	font-size: 16px;
	margin-left: -1px;
}

.by-waiters-table-info {
	line-height: 14px;
	margin-right: 10px;
}

.by-waiters-table-info-time {
	font-weight: 500;
	font-size: 14px;
	color: #A0A0A0;
	text-align: right;
}

.by-waiters-table-info-total {
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	text-align: right;
	color: #1E1D1D;
}

.waiter-footer {
	display: flex;
	gap: 50px;
	height: 50px;
	padding: 7px;
	align-items: center;
	background-color: #fff;
	border-top: 1px solid #DFE3E7;
}

.waiter-footer-item {
	position: relative;
	color: #000;
	cursor: pointer;
}

.waiter-footer-item.active {
	color: #5b73e8;
}

.waiter-footer-item.disabled {
	color: #A0A0A0;
	pointer-events: none;
	cursor: default;
}

.waiter-footer-count {
	position: absolute;
	top: -5px;
	right: -15px;
	font-size: 10px;
}

.btn-standart-width {
	min-width: 125px;
}

.btn-footer {
	max-height: 55px;
	outline: none;
	box-shadow: none;
	width: 110px;
	border: 1.5px solid #A0A0A0;
	border-radius: 5px;
	background: transparent;
	line-height: 14px;
}

.btn-footer-active {
	background: #26AEFB !important;
	color: #FFFFFF !important;
	border: 1px solid #26AEFB;
}

/* WAITER */

/* WAITER order */

.w-20 {
	width: 20%;
}

.w-33 {
	width: 33.33%;
}

.w-40 {
	width: 40%;
}

.w-60 {
	width: 60%;
}

.w-120px {
	width: 120px;
}

.btn-mw {
	min-width: 125px;
}

.order-products-wrapper {
	height: calc(100vh - 140px);
	overflow: auto;
	width: 48%;
}

.order-info-total {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 55px;
	padding: 7px;
	height: 60px;
	background: #EAF1F4;
	border: 1px solid #DFE3E7;
	border-radius: 0;
	line-height: 15px;
}

.order-action-bar {
	position: absolute;
	display: flex;
	justify-content: space-between;
	gap: 5px;
	left: 0;
	bottom: 0;
	padding: 7px;
	height: 55px;
	background: #fff;
	border-radius: 0;
	font-size: 20px;
	margin: 0 calc(var(--bs-gutter-x) * .5);
	width: 97%;
}

.order-info {
	display: flex;
	justify-content: space-between;
	padding: 5px 15px;
	background-color: #fff;
	border-radius: 10px;
	margin-bottom: 7px;
	margin-left: 7px;
	height: 42px;
}

.order-info-left {
	font-weight: 700;
	color: #1E1D1D;
}

.order-info-left-time {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 16px;
	font-weight: 500;
	color: #A0A0A0;
	margin-right: 10px;
}

.order-left {
	height: calc(100vh - 235px);
	border-radius: 0px 10px 0 0px;
	background-color: #fff;
	overflow-y: auto;
}

.order-product.active {
	background-color: #f5deb3;
}

.order-product {
	display: flex;
	justify-content: space-between;
	padding: 2px 5px 2px 0;
	font-weight: 600;
	font-size: 14px;
	color: #1E1D1D;
	border-bottom: 1px solid #A0A0A0;
}

.order-product-quantity {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	background: #EAF1F4;
	border: 1px solid #A0A0A0;
	border-left: none;
	border-radius: 0 10px 10px 0;
	width: 50px;
	height: 35px;
	margin-right: 10px;
}

.order-product-info {
	display: flex;
	justify-content: center;
	flex-direction: column;
	line-height: 18px;
}

.order-product-name {
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.order-product-comment {
	font-size: 12px;
	margin-left: 5px;
}

.order-product-price {
	display: flex;
	justify-content: center;
	flex-direction: column;
	white-space: nowrap;
}

.order-client-wrapper {
	width: 500px;
	border: 1px solid #DFE3E7;
	height: calc(100vh - 50px);
	overflow: auto;
	padding: 10px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.order-right {
	width: 67%;
	display: flex;
	padding: 0 5px;
}

.order-item-box {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	border-radius: 10px;
	width: 105px;
	height: 75px;
	line-height: 19px;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
}

.order-item-box-category {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	border-radius: 10px;
	width: 105px;
	height: 75px;
	gap: 5px;
	line-height: 19px;
	font-size: 15px;
	font-weight: 500;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	cursor: pointer;
}

.order-stoplist-info {
	position: absolute;
	top: 0;
	right: 0;
	background-color: #E91548;
	border-radius: 0 10px 0 0;
	padding: 3px;
	font-weight: 700;
}

.order-saleprice-info {
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: #E91548;
	color: #fff;
	padding: 0 3px;
	font-weight: 700;
	font-size: 12px;
}

.order-printers-wrapper {
	position: absolute;
	background: #fff;
	width: 100%;
	bottom: 63px;
	padding: 10px;
}

.ellipse-3 {
	overflow: hidden;
	display: -webkit-box;
	line-clamp: 3;
	-webkit-box-orient: vertical;
}

.ellipse-1 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.numbers-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 12px;
}

.numbers-item {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	flex-grow: 1;
	cursor: pointer;
	width: 78px;
	height: 68px;
	background: #EAF1F4;
	border: 1px solid #DFE3E7;
	border-radius: 5px;
}

.numbers-item:disabled {
	background-color: #DBDBDB;
	cursor: not-allowed;
}

.numbers-item-inner {
	text-align: center;
	font-weight: 700;
	font-size: 34px;
	line-height: 41px;
	color: #1E1D1D;
	width: 100%;
}

.custom-number {
	margin: 10px;
}

.custom-delivery-modal {
	max-width: 90vw !important;
}

.custom-delivery-modal .modal-content {
	height: 90vh;
}

.h-table-orders {
	height: calc(100vh - 50px);
}

.bottom-progress-bar td {
	height: 5px;
	padding: 0;
	position: relative;
}

.bottom-progress-bar-indicator {
	height: 5px;
	background-color: #34c38f;
}

.bottom-progress-bar-indicator.danger {
	background-color: #f46a6a;
}

.bottom-progress-bar-indicator.success {
	background-color: #5b73e8;
}

.bottom-progress-bar-devider {
	position: absolute;
	z-index: 1;
	display: flex;
	width: 100%;
	top: 0;
}

.bottom-progress-bar-devider div {
	height: 5px;
	border-right: 2px solid #000;
}

.bottom-progress-bar-devider div:nth-child(1) {
	width: 3.3%;
}

.bottom-progress-bar-devider div:nth-child(2) {
	width: 50%;
}

.bottom-progress-bar-devider div:nth-child(3) {
	width: 46.67%;
	border-right: none;
}

.form-check.delivery {
	font-size: 12px;
	padding-left: 10px;
}

.input-button-right.delivery {
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	border: 1px solid #34C38F;
	color: #34C38F;
	background-color: #fff;
	border-radius: 6px;
	width: 24px;
	height: 24px;
	padding: 6px;
}

.input-button-right.delivery .input-icon {
	color: #34C38F;
	font-size: 16px;
}

.input-button-right.delivery:hover .input-icon {
	color: #fff;
}

.input-button-right.delivery:hover:disabled .input-icon {
	color: #34C38F;
}

.max-lines-2 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
}

.delivery-small-input {
	height: 25px;
	border: 1px solid #E4E4E4;
	border-radius: 6px;
	outline: none;
	text-align: center;
	width: 35px;
	margin: 0 5px;
}

.delivery-small-btn {
	border: 1px solid #E4E4E4;
	border-radius: 6px;
	outline: none;
	text-align: center;
	width: 100%;
	height: 36px;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: #fff;
}

.delivery-small-btn.active {
	color: #5b73e8;
}

.delivery-btn {
	margin-top: 10px;
	height: 43px;
	width: 100%;
	color: #fff;
	background-color: #5B73E8;
	border: none;
	border-radius: 6px;
}

.delivery-btn:disabled {
	background-color: #E3E3E3;
	color: #5B73E8;
}

.order-actions-item {
	position: relative;
	background: #F5F6F8;
	height: 60px;
	margin-bottom: 5px;
	width: 100%;
	text-align: center;
	align-items: center;
	display: flex;
	justify-content: center;
	line-height: 14px;
	border: 1px solid #E3E3E3;
	border-radius: 10px;
	cursor: pointer;
}

.order-actions-item:hover {
	background-color: #E1E3E8;
}

.order-actions-item.active,
.order-actions-item.active:focus {
	background-color: #E1E3E8 !important;
	font-weight: bold;
	transition: background-color 0.3s ease;
}

.order-action-item.active {
	background-color: #3b82f6;
	color: white;
	font-weight: bold;
}

.table-hover>tbody>tr:hover>td.bg-primary {
	--bs-table-accent-bg: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.table-hover>tbody>tr:hover>td.bg-danger {
	--bs-table-accent-bg: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.table-hover>tbody>tr:hover>td.bg-success {
	--bs-table-accent-bg: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.table-hover>tbody>tr:hover>td.bg-warning {
	--bs-table-accent-bg: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

/* WAITER order */