html {
	height: 100%;
}

body {
	height: 100%;
	position: relative;
}

#root {
	height: 100%;
}

#layout-wrapper {
	height: 100%;
}

.main-content {
	height: 100%;
}

::-webkit-scrollbar {
	width: 0.3rem;
	height: 0.3rem;
}

::-webkit-scrollbar-thumb {
	background: #8b909e;
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: #b8c0c8;
	border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

th {
	vertical-align: middle;
	font-size: 0.85rem;
}

.authentication-bg {
	height: 100vh;
	background-image: url('../images/bg_login.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.hover:hover {
	color: #5b73e8;
	cursor: pointer;
}

.del {
	text-decoration: line-through;
}

.vertical-middle {
	vertical-align: middle;
}

.vertical-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.vertical-between {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.vertical-end {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.h-fit-content {
	height: fit-content;
}

.tooltip[x-placement^="bottom"],
.show[x-placement^="bottom"]:not(.tooltip) {
	margin-top: 0;
}

.input-borer-only {
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 5px;
}

.btn-rounded {
	border-radius: 30px;
}

.btn-wide {
	padding: 0.42rem 2rem;
}

.btn-wide-2 {
	padding: 0.42rem 0.6rem;
}

.lang-item:hover {
	color: #5b73e8;
}

.color-00 {
	color: #000;
}

.color-dark-theme {
	color: #212529;
}

.bg-border {
	padding: 2px 10px;
	border-radius: 15px;
}

.dshbutton {
	cursor: pointer;
	display: inline-flex;
	align-items: center;
}

.dshbutton .current {
	display: block;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 9999px;
	margin-right: 0.5rem;
	border-width: 3px;
	background-color: #00e396;
	pointer-events: none;
}

.dshbutton .last {
	display: block;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 9999px;
	margin-right: 0.5rem;
	border-width: 3px;
	background-color: #f1b44c;
	pointer-events: none;
}

.cursor {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}

.table-action-button {
	width: 22px;
	height: 22px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
	background-color: inherit;
}

.table-action-button i {
	font-size: 16px;
}

.table-action-primary-button {
	color: #5b73e8;
	border: 1px solid #5b73e8;
}

.table-action-primary-button:hover {
	color: #fff;
	border-color: #5b73e8;
	background-color: #5b73e8;
}

.table-action-success-button {
	color: #34c38f;
	border: 1px solid #34c38f;
}

.table-action-success-button:hover {
	color: #fff;
	border-color: #34c38f;
	background-color: #34c38f;
}

.table-action-danger-button {
	color: #f46a6a;
	border: 1px solid #f46a6a;
}

.table-action-danger-button:hover {
	color: #fff;
	border-color: #f46a6a;
	background-color: #f46a6a;
}

.table-no-image {
	width: 50px;
	height: 50px;
	border-radius: 15px;
	text-align: center;
	background-color: #d9d9d9;
}

.inventory-modal-height {
	max-height: 400px;
	overflow: auto;
}

.modal-460 {
	height: 460px;
	overflow: auto;
}

.inventory-modal-height::-webkit-scrollbar {
	display: none;
}

.btn-wrapper {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	margin-top: 3rem;
}

.fz-12 {
	font-size: 12px !important;
}

.fz-14 {
	font-size: 14px !important;
}

.fz-16 {
	font-size: 16px !important;
}

.fz-18 {
	font-size: 18px;
}

.fz-20 {
	font-size: 20px;
}

.fz-22 {
	font-size: 22px;
}

.fz-24 {
	font-size: 24px;
}

.fz-26 {
	font-size: 26px;
}

.fz-28 {
	font-size: 28px;
}

.fz-30 {
	font-size: 30px;
}

.fz-32 {
	font-size: 32px;
}

.fz-34 {
	font-size: 34px;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}

.settings_title {
	font-weight: 700;
	letter-spacing: 4px;
	text-transform: uppercase;
	color: #5d5d5d;
	margin-bottom: 10px;
}

.rightbar-close-icon {
	right: 5px;
	font-size: 30px;
	position: absolute;
	cursor: pointer;
}

.logoColor {
	color: #5b73e8;
	font-weight: 600;
}

.input-search-icon {
	position: absolute;
	top: 22%;
	right: 9px;
}

.page-header {
	height: 40px;
	margin-bottom: 10px;
}

.page-item:first-child .page-link {
	margin-left: 0;
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #5b73e8;
	border-color: #5b73e8;
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	cursor: auto;
	background-color: #fff;
	border-color: #dee2e6;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.25rem;
	line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0.2rem;
	border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.pagination .page-link-item {
	border-radius: 5px;
}

.pagination .prev-link-pagination {
	border-radius: 5px;
	margin-right: 5px;
	color: rgba(0, 0, 0, 0.6);
}

.pagination {
	display: inline-block;
	justify-content: end;
	padding-left: 0;
	margin: 1rem 0;
	padding: 7px;
	border-radius: 4px;
	font-size: 0.8rem;
}

.pagination>ul {
	background-color: #f0f0f0;
}

.pagination>li {
	display: inline;
	margin-right: 10px;
}

.pagination .page-link-item {
	border-radius: 5px;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	z-index: 3;
	color: #fff;
	cursor: default;
	background-color: #5b73e8;
	border-color: #5b73e8;
}

.pagination>.disabled>a {
	cursor: default;
	pointer-events: none;
	opacity: 0.5;
	background-color: #f0f0f0;
	border-color: transparent;
	border: 1px solid #f0f0f0;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	margin-left: 0;
}

.pagination>li>a,
.pagination>li>span {
	position: relative;
	float: left;
	padding: 5px 12px;
	margin-left: -1px;
	line-height: 1.4;
	color: #5b73e8;
	text-decoration: none;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
}

.pagination>li>a:hover,
.pagination>li>span:hover {
	color: #5b73e8;
}

.pagination .next,
.previous {
	display: none !important;
}

.auth-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	z-index: 1;
	position: relative;
	min-width: 0;
	word-wrap: break-word;
	background-clip: border-box;
	border: 0 solid #f6f6f6;
	border-radius: 0.25rem;
}

.auth-payment-card {
	padding: 12px;
	border-bottom: 2px solid #5b73e8;
	border-radius: 8px;
	box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.calendar-input {
	position: relative;
}

.calendar-input i {
	position: absolute;
	right: 8px;
	top: 8px;
	color: hsl(0, 0%, 80%);
}

.lang-position {
	top: 15px;
	z-index: 99999;
	right: 15px;
	color: #fff;
}



/* IMAGE UPLOAD */
.con-img-upload .img-upload .btn-x-file i {
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	position: relative;
	padding: 4px;
	font-size: 1.4rem;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	justify-content: center;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.1);
	color: #fff;
	text-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.con-img-upload .img-upload .btn-x-file {
	right: 5px;
}

.con-img-upload .img-upload .btn-x-file {
	display: block;
	position: absolute;
	top: 5px;
	padding: 0;
	margin: 0;
	-webkit-transform: translate(20%, -20%);
	transform: translate(20%, -20%);
	background: transparent;
	z-index: 300;
	opacity: 0;
	border: 0;
	cursor: pointer;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	border-radius: 10px;
}

.con-img-upload .img-upload:hover .btn-x-file {
	opacity: 1;
	-webkit-transform: translate(0);
	transform: translate(0);
}

.con-img-upload .img-upload .btn-x-file:hover i {
	border-radius: 50%;
	background: #924040;
}

.con-img-upload .img-upload .btn-x-file:hover~img {
	-webkit-filter: grayscale(80%);
	filter: grayscale(80%);
}

.con-input-upload:hover {
	border: 1px dashed #5b73e8;
}

.con-upload {
	width: 100%;
	position: relative;
}

.con-img-upload {
	width: 100%;
	background: #fff;
	margin-top: 5px;
	padding-right: 5px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-column-gap: 5px;
	-moz-column-gap: 5px;
	column-gap: 5px;
}

.con-img-upload,
.con-img-upload .img-upload {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
}

.con-img-upload {
	overflow: hidden;
	margin: 0;
	padding-right: 0;
	display: flex;
	justify-content: center;
	border-radius: 8px;
}

.con-input-upload {
	background: #f5f5f5;
	width: 200px;
	height: 200px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 8px;
	position: relative;
	-webkit-transition: all 0.25s ease;
	transition: all 0.25s ease;
	border: 1px dashed rgba(0, 0, 0, 0.1);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 15px;
}

.con-img-upload .img-upload {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	background: rgba(0, 80, 0, 0.5);
	background: #fff;
	-webkit-box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
	box-shadow: 0 5px 20px 0 rgb(0 0 0 / 10%);
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 200px;
	height: 200px;
	margin: 5px;
	cursor: pointer;
	float: left;
}

.con-img-upload .img-upload {
	width: 100%;
}

.con-input-upload input {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	left: 0;
	top: 0;
	cursor: pointer;
}

.con-input-upload .text-input {
	min-width: 200px;
	display: block;
	text-align: center;
	margin: 0 !important;
	padding: 0 !important;
	width: 100%;
	font-weight: 700;
}

.con-input-upload {
	float: left;
	margin: 0;
	width: 100%;
}

.table-control {
	position: relative;
}

.table-control-icon {
	position: absolute;
	right: -1px;
	top: -2px;
	cursor: pointer;
}

.table-control-icon:hover {
	color: #5b73e8;
}

/* RIGHTBAAR CHEQUE */

.custom-cheque-table th {
	padding: 0;
}

.cheque-border-bottom {
	border-bottom: 1px solid #eee;
}

.border-bottom-primary {
	border-bottom: 1px solid #5b73e8;
}

.border-bottom-danger {
	border-bottom: 1px solid #f46a6a;
}

.border-none {
	border: none !important;
}

.border-none td {
	border-color: #ddd;
}

.right-sidebar-background {
	position: absolute;
	top: 0px;
	left: 0;
	z-index: 52010;
	background: rgba(0, 0, 0, 0.2);
	width: 100%;
	height: 100vh;
	position: fixed;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	opacity: 1;
}

.right-sidebar {
	overflow-y: auto;
	z-index: 52010;
	width: 500px;
	max-width: 90vw;
	right: 0;
	left: auto;
	height: calc(var(--vh, 1vh) * 100);
	background: #fff;
	position: fixed;
	top: 0;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
	transition: all 0.25s ease;
}

.cheque-no-auth {
	overflow-y: auto;
	min-height: 100vh;
	width: 450px;
	background: #fff;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
	transition: all 0.25s ease;
}

/* LOADER */

#loading-bg {
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: block;
	position: fixed;
}

.loading-logo {
	position: absolute;
	left: calc(50% - 45px);
	top: 40%;
}

.loading {
	position: absolute;
	left: calc(50% - 35px);
	top: 50%;
	z-index: 999;
	width: 55px;
	height: 55px;
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 0 solid transparent;
}

.loading .effect-1,
.loading .effect-2 {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 3px solid transparent;
	border-left: 3px solid rgba(91, 115, 232);
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.loading .effect-1 {
	animation: rotate 1s ease infinite;
}

.loading .effect-2 {
	animation: rotateOpacity 1s ease infinite 0.1s;
}

.loading .effect-3 {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 3px solid transparent;
	border-left: 3px solid rgba(91, 115, 232);
	-webkit-animation: rotateOpacity 1s ease infinite 0.2s;
	animation: rotateOpacity 1s ease infinite 0.2s;
	border-radius: 50%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.loading .effects {
	transition: all 0.3s ease;
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}

@keyframes rotateOpacity {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		opacity: 0.1;
	}

	100% {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
		opacity: 1;
	}
}

/* TABS */
.tabs {
	-webkit-overflow-scrolling: touch;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: 1rem;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	overflow: hidden;
	overflow-x: auto;
	white-space: nowrap;
}

.tabs ul {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.tabs li {
	cursor: pointer;
	display: block;
}

.tabs li:hover {
	color: #5b73e8;
}

.tabs li.active .link {
	border-bottom-color: #5b73e8;
	border-bottom: 4px solid #5b73e8;
	color: #5b73e8;
}

.tabs .link {
	font-weight: 700;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 4px solid #dbdbdb;
	color: #4a4a4a;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: -1px;
	vertical-align: top;
}

.tabs .disabled {
	color: #eee;
	cursor: not-allowed;
}

.tabs-sm .link {
	padding: 0.5rem 1rem;
	font-size: 1rem;
}

/* TABS */

/* search PRODUCT */

.search-not-found {
	display: flex;
	justify-content: center;
	font-size: 20px;
	padding: 2px;
}

.aa-input-container {
	display: inline-block;
	position: relative;
}

.dropdown-search-menu {
	position: absolute;
	display: grid;
	top: 77%;
	width: 100%;
	z-index: 1;
	right: auto;
}

.dropdown-menu-list {
	overflow: auto !important;
	background-color: #fff;
	border: 2px solid rgba(228, 228, 228, 0.6);
	border-top-width: 1px;
	width: 100%;
	margin-top: 10px;
	box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
	font-size: 15px;
	border-radius: 4px;
	box-sizing: border-box;
	max-height: 400px;
	overflow-y: scroll;
	margin-bottom: 5px;
}

.dropdown-menu-result-link {
	padding: 5px;
	display: flex;
	flex-direction: column;
}

.dropdown-menu-result-inner {
	display: flex;
	flex-direction: row;
}

.search-barcode {
	position: absolute;
	top: 2px;
	right: 7px;
	cursor: pointer;
	font-size: 25px;
}

.search-barcode:hover {
	color: #5b73e8;
}

.input-plus-icon {
	position: absolute;
	top: 7px;
	right: 35px;
	cursor: pointer;
}

.text-sm {
	font-size: 0.8rem;
}

.input-right-icon {
	position: absolute;
	top: 8px;
	right: 8px;
	cursor: pointer;
	font-size: 18px;
}

.input-right-icon:hover {
	color: #5b73e8;
}

.w-250px {
	width: 250px;
}

.min-h500 {
	height: calc(100vh - 125px);
}

.grouping-table {
	overflow-y: auto;
	height: calc(100vh - 320px);
}

/* TABLE STYLE */
.table-first-column-clickable:hover {
	text-decoration: underline !important;
}

.table-tr-active {
	background-color: #f8f9fa;
}

.bg-on-hover:hover {
	background-color: rgba(34, 41, 47, 0.05);
	cursor: pointer;
}

.tr-middle td {
	vertical-align: middle;
}

.th-sortable {
	cursor: pointer;
	position: relative;
	padding: 0.35rem 1rem 0.35rem 0.35rem !important;
}

.table-sort {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	overflow: hidden;
	position: absolute;
	right: 2px;
	top: 0;
}

/* .table {
	overflow: hidden;
} */

.table-responsive {
	min-height: 240px;
}

.custom-truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.auto-width-input {
	text-align: center;
	padding: 0px 5px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	outline: none;
	max-width: 150px;
	min-width: 85px;
	width: 85px;
	height: 38px;
}

.auto-width-input2 {
	padding: 0px 5px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	outline: none;
	max-width: 150px;
	min-width: 85px;
	width: 85px;
	height: 30px;
}

.upload-from-excel {
	cursor: pointer;
	font-weight: inherit;
	font-size: inherit;
	color: inherit;
	text-overflow: initial;
	margin-bottom: 0;
	padding: 0;
	white-space: nowrap;
}

.pin {
	width: 30px;
	height: 30px;
	border-radius: 50% 50% 50% 0;
	background: #ea4335;
	position: absolute;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
	left: 50%;
	top: 50%;
	margin: -20px 0 0 -20px;
	-webkit-animation-name: bounce;
	-moz-animation-name: bounce;
	-o-animation-name: bounce;
	-ms-animation-name: bounce;
	animation-name: bounce;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	-ms-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
	-o-animation-duration: 1s;
	-ms-animation-duration: 1s;
	animation-duration: 1s;
}

.pin:after {
	content: "";
	width: 14px;
	height: 14px;
	margin: 8px 0 0 8px;
	background: #811411;
	position: absolute;
	border-radius: 50%;
}

/* filter BLOCK */

.filter-block {
	background-color: #f8f8f8;
	border-radius: 15px;
	padding: 0.5rem !important;
	margin-bottom: 1rem;
	display: flex;
	justify-content: space-between;
}

.sort-active {
	color: #5b73e8;
}

.required-mark {
	color: #f46a6a;
}

.required-border {
	border: 1px solid #f46a6a !important;
}

.form-wrapper {
	padding: 30px 100px;
	border: 3px solid #5882C1;
	border-radius: 40px;
	background-color: rgb(165 201 255 / 50%);
}

.login-form {
	width: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 5px;
}

.login-about {
	position: absolute;
	color: #fff;
	bottom: 10px;
	left: 10px;
	z-index: 10;
}

.login-about a {
	color: inherit;
	text-decoration: underline !important;
}

.pagination-input {
	width: 40.13px;
	outline: none;
	padding: 5px 5px;
	line-height: 1.4;
	text-decoration: none;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
}

.pagination-input-icon {
	position: absolute;
	cursor: pointer;
	right: 10px;
	top: 13px;
}

.menu-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	width: 331px;
}

.menu-box {
	background-color: #eeeeee;
	cursor: pointer;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	overflow: hidden;
	align-items: center;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	font-size: 15px;
	font-weight: 500;
	height: 75px;
	justify-content: center;
	text-align: center;
	line-height: 19px;
	margin-bottom: 5px;
	margin-right: 5px;
	width: 105px;
	padding: 3px;
}

.color-picker-body {
	position: absolute;
	z-index: 1;
}

.color-picker-icon {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 20px;
	cursor: pointer;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(238, 238, 238);
}

.card-right {
	text-align: end;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.bg-primary-gradient {
	background-image: linear-gradient(to left,
			rgba(1, 98, 232, 0.5) 0%,
			#0162e8 100%) !important;
}

.bg-danger-gradient {
	background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
}

.bg-success-gradient {
	background-image: linear-gradient(to left,
			#48d6a8 0%,
			#029666 100%) !important;
}

.bg-warning-gradient {
	background-image: linear-gradient(to left, #efa65f, #f76a2d) !important;
}

.nowrap {
	white-space: nowrap;
}

.chart-legend-color {
	margin-top: 4px;
	margin-right: 2px;
	border-radius: 50%;
	width: 8px;
	height: 8px;
}

.pos-settings-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 0.5rem;
}

.pos-settings-left {
	font-weight: 600;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 70%;
}

.pos-settings-right {
	font-weight: 600;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 30%;
}

.input-smile-icon {
	position: absolute;
	right: 2px;
	top: 5px;
	font-size: 20px;
	cursor: pointer;
}

.input-smile-wrapper {
	position: absolute;
	display: flex;
	flex-wrap: wrap;
	width: 170px;
	height: 150px;
	background: #eee;
	z-index: 1;
	right: 0;
	overflow-x: auto;
	gap: 10px 8px;
	padding: 5px;
}

.input-smile-wrapper div {
	width: 24px;
	height: 24px;
	font-size: 18px;
	cursor: pointer;
}

.flex-gap10 {
	display: flex;
	gap: 10px;
}

.modifier-selected {
	background-color: #eee;
}

.modifier-item:hover {
	cursor: pointer;
	background-color: #eee;
}

.brr-eee {
	border-right: 1px solid #eee;
}

.app-version {
	position: absolute;
	bottom: 5px;
}

/* Theme Page */

.border-right {
	border-right: 1px solid #bfc0c2;
}

.themes {
	display: flex;
	flex-direction: column;
	background-color: #fff;
}

.themes-buttons {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-bottom: 20px;
	margin-top: 10px;
}

.themes-button {
	height: 50px;
	width: 50px;
	border: none;
	border-radius: 50%;
}

.themes-button.light {
	color: #FDB813;
	border: 1px solid #F0F0F0;

}

.themes-button.light:hover, .themes-button.light.active {
	transition: 0.2s;
	background-color: #FDB813;
	color: #fff;
	border: 1px solid #fff;
}

.themes-button.dark {
	color: #5b92e5;
	border: 1px solid #F0F0F0;
}

.themes-button.dark:hover, .themes-button.dark.active {
	transition: 0.2s;
	/* background-color: #fff; */
	border: 1px solid #F0F0F0;
	background-color: #5b92e5;
	color: #fff
}

.themes-button.light i, .themes-button.dark i {
	font-size: 20px;
	font-weight: 700;
}

.themes-colors {
	height: 65vh;
	overflow: auto;
	padding: 5px;
}

.theme-item {
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
	box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
	border: 1px solid #f5f6f8;
	cursor: pointer;
}

.theme-item.selected {
	border: 1px solid #5b73e8;
}

.theme-item-light,
.theme-item-dark {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
}

.theme-item-dark {
	margin-bottom: 0;
}

.theme-color {
	width: 15px;
	height: 15px;
	border-right: 1px solid #f5f6f8;
	border-radius: 5px;
	margin-right: 10px;
	box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
}

.theme-color:last-child {
	margin-right: 0;
}

.theme-wrapper {
	overflow: hidden;
}

.theme-title {
	font-size: 32px;
	font-weight: 700;
}

.theme-page {
	width: 460px;
	border: 1px solid #a7a2a2;
}

.theme-online-menu,
.theme-bot {
	display: flex;
	flex-wrap: nowrap;
	gap: 20px;
}

.telegram-header {
	background-color: #427EA6;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 100px;
	padding: 25px 30px;
	color: #fff;
}

.telegram-back {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 22px;
}

.telegram-name {
	font-weight: 400;
	font-size: 26px;
}

.telegram-chat {
	height: 80px;
	background-color: #C3CF8C;
}

.telegram-image img {
	width: 48px;
	height: 48px;
	border-radius: 50%;
}

.menu-list-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.product-telegram-image {
	position: relative;
	display: flex;
	justify-content: center;
	max-width: 100%;
	max-height: 100%;
	height: 80px;
}

.product-telegram-title {
	margin: 0 0 10px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	flex-grow: 1;
	font-weight: 700;
	font-size: 18px;
	text-align: center;
	line-height: 17px;
}

.product-telegram-price__current {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	font-size: 18px;
	width: 100%;
}

.menu-list-header-action {
	display: flex;
	font-size: 24px;
}

.theme-menu-item__link img {
	max-width: 100%;
	max-height: 100%;
	width: 100%;
	margin-bottom: 10px;
	border-radius: 10px;
	object-fit: contain;
}

.theme-menu-item {
	position: relative;
	width: 100%;
	overflow: hidden;
	margin-bottom: 15px;
}

.theme-menu-item-search-form {
	margin-bottom: 20px;
}

.theme-menu .pos-header {
	padding: 10px 0 30px;
}

.theme-menu .pos-image img {
	width: 48px;
	height: 48px;
}

.theme-menu .pos-title {
	font-size: 24px;
}

.theme-menu .pos-info__block {
	margin: 0;
}

.product-button.theme {
	width: 100%;
}

.product-button.theme.minus {
	margin-right: 10px;
}

.product-count.theme {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 13px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	font-weight: 500;
	font-size: 16px;
}

.setting-tabs {
	display: flex;
	align-items: center;
}

.setting-tab {
	border-bottom: 2px solid #dbdbdb;
	padding: 10px 20px;
	margin-bottom: 20px;
	cursor: pointer;
}

.setting-tab.active {
	border-bottom: 2px solid #5b73e8;
	color: #000;
}

.table-input {
	height: 25px;
}

/* Theme Page End */

.menu-image-button {
	position: absolute;
	top: 15px;
	right: 5px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border-radius: 50%;
	cursor: pointer;
	z-index: 5;
	box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.4);
	transition: 0.3s;
}

.menu-image-button i {
	font-size: 18px;
}

.con-img-upload .img-upload:hover .menu-image-button {
	top: 45px;
}

.menu-gallery-item img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	margin: 10px 0;
	cursor: pointer;
}

.active .menu-gallery-item img {
	border: 1px solid #5b73e8;
	border-radius: 10px;
	padding: 5px;
}

.payment-button {
	margin-right: 10px;
	border-radius: 10px;
}

.payment-button.payme {
	background-color: #33CCCC;
	color: #fff;
}

.payment-button.click {
	background-color: #00DEFF;
	color: #fff;
}

.payment-button.uzum {
	background-color: #7000ff;
	color: #fff;
}

/* Canvas */

.canvas-right-menu {
	width: 22vw;
	position: fixed;
	top: 50px;
	right: 0;
	background-color: #fff;
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
	height: calc(100vh - 50px);
	z-index: 5;
	padding: 10px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.canvas-input-buttons {
	position: relative;
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 10px;
}

.canvas-input-button {
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	cursor: pointer;
	border: 1px solid #cccccc !important;
	border-radius: 50%;
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
	background-color: #fff;
}

.color-picker {
	position: absolute;
	left: 0;
	top: 50px;
	z-index: 2;
}

.canvas-right-menu-buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	gap: 10px;
	margin-bottom: 20px;
}

.canvas-input {
	position: relative;
	width: 100%;
	margin-bottom: 20px;
}

.canvas-input label {
	position: absolute;
	top: -7px;
	left: 15px;
	background-color: #fff;
	color: #515151;
	font-size: 12px;
}

.canvas-input input {
	width: 100%;
	border: 1px solid #cccccc;
	height: 45px;
	border-radius: 10px;
	outline: 0;
	padding: 0 10px;
}

.canvas-list {
	display: flex;
	flex-direction: column;
	max-height: 500px;
	overflow: auto;
}

.canvas-item {
	cursor: pointer;
	font-weight: 500;
	user-select: none;
	font-size: 16px;
	margin-bottom: 5px;
	padding: 5px 10px;
	border-bottom: 1px solid #c6c6c6;
}

.canvas-item-child {
	margin-left: 10px;
	padding: 10px 10px;
	border-bottom: 1px solid #c6c6c6;
}

.canvas-item-child:last-child {
	border-bottom: none;
}

/* Canvas end */


.radio-round {
	display: inline-block;
	width: 18px;
	height: 18px;
	vertical-align: middle;
	cursor: pointer;
	border-radius: 50%;
	border: 1px solid #adb5bd;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	line-height: 44px;
	margin-right: 7px;
}

.form-check {
	display: flex;
	min-height: 1.35rem;
	margin-bottom: 0.125rem;
}

.form-check-input {
	min-width: 18px;
	height: 18px;
	margin: 0 10px 0 0;
	vertical-align: top;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	border: 1px solid #adb5bd;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-check-input[type=radio] {
	border-radius: 50%;
}

.form-check-input:checked[type="radio"] {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-label {
	font-size: 14px;
}

.no-scroll::-webkit-scrollbar {
	display: none;
}

.input-button-right {
	position: absolute;
	background-color: #00e396;
	right: 1px;
	top: 1px;
	height: 36.47px;
	width: 40px;
	border-radius: 0 1px 1px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border: none;
	z-index: 5;
}

.input-button-right:disabled {
	cursor: not-allowed;
	background-color: #e9ecef;
}

.input-button-right i {
	color: #fff;
	font-size: 20px;
}

.input-button-right:hover:not(:disabled) {
	background-color: #00cf8a;
}

.text-hover:hover {
	cursor: pointer;
	color: #5b73e8;
}

.ymap-coordinates-input {
	position: absolute;
	z-index: 1;
	width: 300px;
}

.bottom-tabs {
	display: flex;
	position: absolute;
	bottom: 0;
	height: 36px;
}

.bottom-tabs-item {
	display: flex;
	justify-content: center;
	flex-direction: column;
	background-color: #f9fbfd;
	border-color: #f9fbfd;
	height: 36px;
	padding: 0 12px;
	cursor: pointer;
}

.bottom-tabs-item:hover {
	background-color: #e8ebee;
	border-color: #e8ebee;
}

.bottom-tabs .active {
	background-color: #5b73e8;
	border-color: #5b73e8;
	color: #fff;
}

.input-inner-icon {
	cursor: pointer;
	position: absolute;
	right: 0;
	top: -5px;
}

.input-inner-icon:hover {
	color: #5b73e8;
}

.text-vertical {
	left: calc(50% - 0.5rem);
	line-height: 1;
	padding-top: 0.5rem;
	transform: rotate(180deg);
	writing-mode: vertical-rl;
}

.table-column-fixed {
	max-width: 100%;
	overflow-x: auto;
}

.table-column-fixed table {
	position: relative;
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: auto;
	width: 100%;
	border: none;
	border-radius: 0.5rem;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	white-space: nowrap;
}

.table-column-fixed table * {
	border: 1px solid #eee;
}

.table-column-fixed table thead tr {
	color: #2d3748;
	font-size: 1rem;
	font-weight: 500;
	text-align: left;
}

.table-column-fixed table thead tr th {
	background: #fff;
	padding: 0.25rem;
	vertical-align: middle;
}

.table-column-fixed table tbody td {
	background: #fff;
}

.table-column-fixed table tbody td {
	padding: 0.25rem;
	vertical-align: middle;
}

.table-column-fixed>table>tr>th:first-child, table>tr>td:first-child {
	position: sticky;
	left: 0;
	background-color: #fff;
	box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.1);
}

.td-input {
	padding: 0 !important;
	height: 100%;
}

.td-input input {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: none;
	border-radius: 0;
	height: 100%;
	outline: none;
	padding: 0.42rem 0.2rem;
}

.td-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 0;
}

.progress-container {
	position: relative;
	height: 100%;
}

.progress-bar {
	height: 100%;
}

.tr-bold td {
	font-weight: bold;
}

.tabs.second {
	justify-content: flex-start;
	gap: 15px;
	border-bottom: 1px solid #E6E6E6;
	overflow: hidden;
}

.tab {
	padding-bottom: 5px;
	cursor: pointer;
}

.tab.active {
	border-bottom: 1px solid #5b73e8;
	color: #000;
}


@media (max-width: 768px) {
	.authentication-left {
		display: none;
	}

	.authentication-right {
		width: 100%;
	}

	.form-wrapper {
		padding: 20px 40px;
	}

	.login-form {
		width: auto;
	}
}

/* Custom select */

.custom-select {
	position: relative;
	width: 100%;
	min-height: 24px;
}

.custom-select-menu {
	position: fixed;
	opacity: 0;
	visibility: hidden;
	transition: 0.2s;
	background-color: #fff;
	height: max-content;
	max-height: 200px;
	overflow: auto;
	box-shadow: 0 10px 20px rgba(15, 34, 58, 0.22) !important;
}

.custom-select-menu.active {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}

.custom-select-field {
	position: relative;
	width: 100%;
	background-color: #fff;
	cursor: pointer;
	min-height: 24px;
	display: flex;
	align-items: center;
}

.custom-select-field input {
	width: auto;
	border: none;
	outline: none;
	position: absolute;
	left: 0;
	background: transparent;
	width: 100%;
	padding: 0;
}

.custom-select-field input:disabled {
	cursor: not-allowed;
}

.custom-select-field input.active {
	background-color: #fff;
}

.custom-select-field .icon {
	position: absolute;
	right: 2px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 20px;
}

tr:focus,
tr.selected {
	background-color: #d3d3d3;
}

tr:focus-within {
	outline: none;
}

/* Custom select end */

.preview-info {
	line-height: 16px;
}

/* Table dropdown */

.table-dropdown {
	text-align: center;
}

.table-dropdown .dropdown-menu {
	box-shadow: 0px 0px 32px -10px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 7px;
	min-width: 200px;
	max-width: 300px;
}

.table-dropdown .dropdown-toggle:after {
	display: none;
}

.table-dropdown .dropdown-toggle {
	padding: 0;
	font-size: 16px;
	border: none;
	color: inherit;
}

.table-dropdown .dropdown-toggle:hover,
.table-dropdown .btn-outline-primary.dropdown-toggle.show {
	background-color: transparent;
	color: inherit;
}

.table-dropdown-item {
	display: flex;
	align-items: center;
	gap: 7px;
	padding: 3px 5px;
	cursor: pointer;
	transition: 0.3s;
	font-size: 0.9rem;
	color: inherit;
	border-radius: 5px;
}

.table-dropdown-item i {
	font-size: 1rem;
}

a.table-dropdown-item:hover {
	color: inherit;
}

.table-dropdown-item:hover {
	background-color: #f6f6f6;
}

/* Table dropdown end */

.tooltip {
	z-index: 1151 !important;
}

.weekdays {
	display: flex;
}

.days {
	display: flex;
	flex-wrap: wrap;
}

.day,
.weekday {
	width: calc(100% / 7);
	border-bottom: 1px solid #DDDDDD;
	border-right: 1px solid #DDDDDD;
}

.weekday:first-child,
.day:first-child,
.days :nth-child(n+7) {
	border-left: 1px solid #DDDDDD;
}

.weekday {
	padding: 5px;
	border-top: 1px solid #DDDDDD;
}

.day {
	padding: 5px 15px;
	background-color: #f5f5f5;
	height: 120px;
	transition: 0.25s;
	color: #b1b1b1;
}

.day.active {
	background-color: #fff;
	color: #626262;
}

.day:hover {
	background-color: #dddddd;
}

.calendar-expenses {
	display: flex;
	flex-direction: column;
	gap: 2px;
	max-height: 66px;
	overflow: auto;
}

.calendar-expenses .custom-tooplip {
	width: fit-content;
}

.calendar-expense {
	color: var(--bs-success);
	width: fit-content;
}

/* CATEGORY */
.category-selected {
	background-color: #eee;
}

.step-selected {
	background-color: #eee;
}

.mini-btn {
	color: #fff;
	background-color: #34c38f;
	border-color: #34c38f;
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0;
	font-size: 0.9rem;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* CATEGORY */

.react-custom-select:disabled, .react-custom-select[readonly] {
	cursor: not-allowed;
}

.react-custom-select:disabled, .react-custom-select[readonly] {
	background-color: #f2f2f2;
	opacity: 1;
}

.external-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f8f9fc;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
	font-family: Arial, sans-serif;
	font-size: 14px;
	color: #333;
	cursor: pointer;
	height: 50px;
}

.external-menu-arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 33px;
	cursor: pointer;
	font-size: 22px;
}

.external-menu-text {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
}

.external-menu-icons {
	width: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.external-menu-icons:hover {
	background-color: #e2e6ed;
}

.external-menu-icons i {
	font-size: 20px;
	color: #333;
}

.external-menu.dragging {
	background-color: #f0f8ff;
	border: 2px dashed #007bff;
	transition: background-color 0.3s ease;
}

.external-menu-icons:active {
	cursor: grabbing;
}

/* PRODUCT-DETAIL-TAB */
.product-detail-tabs {
	display: flex;
}

.product-detail-tabs .item {
	display: flex;
	justify-content: center;
	font-size: 20px;
	width: 100%;
	font-weight: 500;
}

.product-detail-tabs .item svg {
	width: 20px;
	margin-right: 3px;
}

.product-detail-tabs .active {
	border-bottom: 1px solid #1E1D1D;
}

.order-payment-input {
	width: 107px;
	height: 68px;
	background: transparent;
	border: none;
	outline: none;
	font-weight: 700;
	font-size: 50px;
	text-align: center;
}

.product-ingredients-wrapper {
	display: flex;
	font-size: 20px;
}

.product-ingredients-left {
	width: 70%;
	font-weight: 500;
}

.product-ingredients-right {
	display: flex;
	justify-content: flex-end;
	width: 30%;
}

.no-comment-wrapper {
	align-items: center;
	display: flex;
	justify-content: center;
}

.comment-wrapper {
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
	align-content: flex-start;
}

.comment-button {
	position: relative;
	width: calc(33% - 10px);
	margin: 5px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 75px;
	gap: 5px;
	line-height: 18px;
	font-size: 15px;
	font-weight: 500;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	overflow: hidden;
	background-color: #3AB434;
	border: none;
	outline: none;
	color: #fff;
}

.comment-info {
	position: absolute;
	top: 0;
	right: 0;
	width: 25px;
	background: #E91548;
}

.v-hidden {
	visibility: hidden;
}

.product-parent {
	cursor: pointer;
	border-bottom: 1px solid #000;
}

/* PRODUCT-DETAIL-TAB */